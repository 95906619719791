import React from "react";
import { CardBody, Row, Col } from "reactstrap";

function NotAvailable() {
    return (
        <>
            <div className="content">
                <Row>
                    <Col md="12">
                        <CardBody className="mt-4 mb-4">
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <h1 style={{ fontSize: 24 }} className="labelText">This page is not accessible during testing.</h1>
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default NotAvailable;
