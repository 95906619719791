/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import logo from "assets/img/Logo.png";
import "assets/css/custom-css.css"

import { Button } from "reactstrap"
import getJWTToken from "../../config/jwtToken";
import klaxonLogo from "assets/img/klaxonImages/klaxonFull.png";
import klaxonLogoBlack from "assets/img/klaxonImages/KlaxonLogoBlack.png";

var ps;

function Sidebar(props) {

    const navigation = useNavigate()

    const handleNavigateFeedback = () => {
        navigation("/admin/feedback")
    }

    const handleNavigateFAQ = () => {
        navigation("/faq")
    }

  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes) => {

        //get only admin layouts
        const userToken = getJWTToken().decoded.nameid
        const superUser = [1, 2, 3, 4, 6, 9]
        const isSuperUser = superUser.includes(Number(userToken))
        let excludedRoutes = ["Review", "Profile", "Create Podcast", "Feedback", "Ads", "Podcast Blocking", "Manage Businesses", "Ads Wizard", "Not Available"]
        
        if (isSuperUser) {
            const modifiedExcludedRoutes = excludedRoutes.filter(route => route !== "Ads" && route !== "Podcast Blocking");
            excludedRoutes = modifiedExcludedRoutes
        }

        if (!excludedRoutes.includes("Advert Wizard")) {
            excludedRoutes.push("Advert Wizard");
        }
        
        let adminRoutes = routes.filter(route => route.layout === "/admin" && !excludedRoutes.includes(route.name))
        

    return adminRoutes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }

      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <Link to={prop.layout + prop.path}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </Link>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  return (
    <div
      className="sidebar"
          data-active-color={props.activeColor}
          style={{ position: "fixed", zIndex: 4 }}
    >
      <div className="sidebar-container">
        <div className="logo">
          
          <a
            href="/home"
            className="simple-text logo-normal"
                  >
                      <div style={{display: "flex", justifyContent: "center"}}>
                          <img src={!props.isLightMode ? klaxonLogo : klaxonLogoBlack} style={{ width: 150 }} />
                      </div>
          </a>
          {/* <img src={logo} alt="Klaxon.AI" /> */}
        </div>

              <div className="sidebar-wrapper" ref={sidebar} style={{ overflow: "auto" }}>
                  <div style={{marginBottom: "20%"}}>
                      <Nav style={{}}>{createLinks(props.routes)}</Nav>
                  </div>

                  <div style={{display: "flex" }} className="navExtraSection">
                      <div style={{ width: "100%", textAlign: "center" }}>
                          <div className="p-2 formStyle" style={{ width: "80%", margin: "auto", marginBottom: 20, borderRadius: 15 }}>
                              <p style={{ fontSize: 16 }}><strong>Need help?</strong></p>
                              <p style={{ }}>Check out our FAQ</p>
                              <button className="buttonPrimary buttonStyle" style={{ width: "70%", marginBottom: 10 }} onClick={handleNavigateFAQ}>FAQ</button>
                          </div>
                          <u style={{ cursor: "pointer" }} onClick={handleNavigateFeedback}>Tell us what you think</u>
                          <p style={{ marginTop: 25 }}>Version v1.0.1</p>
                      </div>
                  </div>
              </div>


          </div>
          
    </div>
  );
}

export default Sidebar;
