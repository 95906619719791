import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment"
import { FormGroup, Input, Row, Col, Label, Button } from "reactstrap";
import { arrayMove, SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

const DragHandle = SortableHandle(() => <span className="" style={{ cursor: "pointer", fontSize: 22, position: "relative", left: 0, zIndex: 999, bottom: 10 }}>
    <span style={{ position: "absolute", left: -5 }}>::</span>
</span>)

const SortableItem = SortableElement(({ value, podcastSections, setPodcastSections, showAdverts, handleAddNewSection, handleAddNewAd, handleRemoveSection, isMultiSections, advertArray, handleAdSelect, handleRemoveAd, isTitleEdit, setIsTitleEdit, handleGetLength, textAreaRef, isCharLimit, setIsCharLimit, isBusiness }) => (
    <li className="findMark">
        <Row className="align-items-center justify-content-around">
            <DragHandle /> 

            {value.type === "section" ?
                <div style={{ width: "100%", position: "relative" }}>
                    <Col>
                        <FormGroup>
                            <Row className="justify-content-between">
                                <Col sm="3" className="mt-1 mb-1">
                                    {isTitleEdit === value.id ?
                                        <Input value={podcastSections[value.id].sectionName}
                                            placeholder="Enter section name"
                                            onChange={(e) => {
                                                const copyArr = [...podcastSections]
                                                copyArr[value.id] = { ...copyArr[value.id], sectionName: e.target.value }
                                                setPodcastSections(copyArr)
                                            }}
                                            onBlur={() => setIsTitleEdit(null)}
                                            className="placeholderWhite inputStyle"
                                        /> :
                                        <Label>{podcastSections[value.id]?.sectionName !== "" ? podcastSections[value.id]?.sectionName : "Section"} <i class="fa fa-pen-to-square" onClick={() =>setIsTitleEdit(value.id)} /></Label>
                                   }
                                </Col>
                                {isMultiSections && <div style={{ position: "absolute", right: -15, top: "50%" } }>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore"
                                        style={{ color: "#f33b30", right: -15 }}
                                        id="tooltip808966390"
                                        size="sm"
                                        type="button"
                                        onClick={() => handleRemoveSection(value.id)}
                                    >
                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                    </Button>
                                </div>}
                            </Row>
                            <Input type="textarea" className="placeholderWhite inputStyle" value={value.value} onChange={(e) => {

                                if (e.target.value.length <= 5000) {
                                    e.target.style.height = "auto"
                                    e.target.style.removeProperty('border');
                                    setIsCharLimit(prevState => ({ ...prevState, [value.id]: false }));
                                    e.target.style.height = `${e.target.scrollHeight}px`
                                    e.target.style.maxHeight = "none"
                                    const copyArr = [...podcastSections]
                                    copyArr[value.id] = { ...copyArr[value.id], value: e.target.value }
                                    setPodcastSections(copyArr)
                                } else {
                                    setIsCharLimit(prevState => ({ ...prevState, [value.id]: true }));
                                    e.target.style.setProperty('border', '1px solid #F33B30', 'important');
                                    const copyArr = [...podcastSections]
                                    copyArr[value.id] = { ...copyArr[value.id], value: e.target.value }
                                    setPodcastSections(copyArr)
                                }

                                
                            }}
                                onBlur={() => handleGetLength()}
                                style={{maxHeight: "none", height: 150} }
                            />
                        </FormGroup>
                    </Col>
                </div> :
                <div style={{ width: "100%", display: "flex" }} className="mt-1 mb-1">
                    <Col sm="4">
                        <FormGroup>
                            <Row className="justify-content-between">
                                <Col sm="3"><Label>Advert</Label>

                                </Col>
                                <div style={{ position: "relative" }}>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore"
                                        style={{ color: "#f33b30", position: "absolute", right: -15, top: 20 }}
                                        id="tooltip808966390"
                                        size="sm"
                                        type="button"
                                        onClick={() => handleRemoveAd(value.id)}
                                    >
                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                    </Button>
                                </div>
                            </Row>
                            <Select
                                className="react-select "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                options={advertArray}
                                value={advertArray.find((e) => e.value === podcastSections[value.id].value)}
                                onChange={(adObj) => handleAdSelect(adObj, value.id)}
                            />
                        </FormGroup>
                    </Col>
                </div>

        }

        </Row>
        <Row className="m-2">
            <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => handleAddNewSection(value.id)}>Add New Section</button>
            {isBusiness && showAdverts && <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => handleAddNewAd(value.id)}>Add Advert</button>}

            {isCharLimit[value.id] && (
                <p style={{ color: "#F33B30", marginLeft: "auto" }}>There is a maximum of 5000 characters per section</p>
            ) }
        </Row>
    </li>
))

const SortableList = SortableContainer(({ children, isMultiSections }) => {
    return <ul style={{paddingLeft: 0} }>{children}</ul>
})

const BStep4 = React.forwardRef((props, ref) => {
    const [dropdownVoices, setDropdownVoices] = useState([])
    const [selectedVoice, setSelectedVoice] = useState([])
    const makeDropdownVoices = () => {
        const formatVoices = props.dropdownVoices.map((e) => {
            return ({
                label: e.voiceName,
                value: e.id ? e.id : e.voiceId
            })
        })
        setDropdownVoices(formatVoices)
        setSelectedVoice(formatVoices.find((e) => e.value === props.baseObject.defaultVoiceId))
    }

    const handleSelectNewVoice = (voice) => {
        setSelectedVoice(voice)
        props.setBaseObject({ ...props.baseObject, defaultVoiceId: voice.value })
    }

    useEffect(() => {
        if (props.dropdownVoices.length > 0 && props.baseObject?.defaultVoiceId) makeDropdownVoices()
    }, [props.dropdownVoices, props.baseObject])


    const [podcastIntro, setPodcastIntro] = useState({
        type: "section", value: "", sectionName: "Intro", duration: 0
    })

    const [podcastSections, setPodcastSections] = useState([
        { type: "section", value: "", id: 0, sectionName: "", duration: 0 },
    ])
    const [podcastOutro, setPodcastOutro] = useState({
        type: "section", value: "", sectionName: "Outro", duration: 0
    })
    const [isTitleEdit, setIsTitleEdit] = useState(null)

    const [isPreAd, setIsPreAd] = useState(false)
    const [preAdSelect, setPreAdSelect] = useState({ type: "advert", value: "", sectionName: "", duration: 0 })
    const handlePreAdSelect = (adObject) => {
        setPreAdSelect({
            ...preAdSelect,
            value: adObject.value,
            duration: convertAdvertLength(adObject.duration)
        })

        handleGetLength()
    }
    const handlePreAdRemove = () => {
        setPreAdSelect({
            ...preAdSelect,
            value: "",
            duration: ""
        })
        setIsPreAd(false)
    }
    const [isPostAd, setIsPostAd] = useState(false)
    const [postAdSelect, setPostAdSelect] = useState({ type: "advert", value: "", sectionName: "", duration: 0 })
    const handlePostAdSelect = (adObject) => {
        setPostAdSelect({
            ...postAdSelect,
            value: adObject.value,
            duration: convertAdvertLength(adObject.duration)
        })

        
        handleGetLength()
    }
    const handlePostAdRemove = () => {
        setPostAdSelect({
            ...postAdSelect,
            value: "",
            duration: ""
        })
        setIsPostAd(false)
    }

    const handleAddNewSection = (indexToPlace) => {
        const copyArr = [...podcastSections]
        const newSection = { type: "section", value: "", sectionName: "", duration: 0 };
        copyArr.splice(indexToPlace + 1, 0, newSection);
        refreshIndexValues(copyArr)
    }

    const handleForceNewSection = () => {
        const copyArr = [...podcastSections]
        const newSection = { type: "section", value: "", sectionName: "", duration: 0 }
        copyArr.unshift(newSection)
        refreshIndexValues(copyArr)
    }

    const handleRemoveSection = (indexToRemove) => {
        if (podcastSections.length === 1) return

        const copyArr = [...podcastSections]
        const withoutSection = copyArr.filter((_, index) => index !== indexToRemove)
        refreshIndexValues(withoutSection)
        handleGetLength(withoutSection)
    }

    const handleAddNewAd = (indexToPlace) => {
        const copyArr = [...podcastSections]
        const newAdvert = { type: "advert", value: "", sectionName: "", duration: 0 };
        copyArr.splice(indexToPlace + 1, 0, newAdvert);
        refreshIndexValues(copyArr)
    }

    const handleForceNewAd = () => {
        const copyArr = [...podcastSections]
        const newAdvert = { type: "advert", value: "", sectionName: "", duration: 0 }
        copyArr.unshift(newAdvert)
        refreshIndexValues(copyArr)
    }

    const refreshIndexValues = (arr) => {
        const newArr = []
        for (let i = 0; i < arr.length; i++) {
            const newObj = { type: arr[i].type, value: arr[i].value, id: i, sectionName: arr[i].sectionName, duration: arr[i].duration }

            if (arr[i].originalId) {
                newObj.originalId = arr[i].originalId
            }

            newArr.push(newObj)
        }
        setPodcastSections(newArr)
    }

    const handleRemoveAd = async (indexToRemove) => {

        const copyArr = [...podcastSections]
        const withoutAd = copyArr.filter((_, index) => index !== indexToRemove)
        await refreshIndexValues(withoutAd)
        handleGetLength(withoutAd)

    }

    const [isMultiSections, setIsMultiSections] = useState(false)
    const checkMultipleSections = () => {
        let countSection = 0

        podcastSections.forEach((e) => {
            if (e.type === "section") {
                countSection++
            }
        })

        if (countSection > 1) {
            setIsMultiSections(true)
        } else {
            setIsMultiSections(false)
        }
    }

    const convertAdvertLength = (timestamp) => {
        const parts = timestamp.split(':');
        const hours = parseInt(parts[0], 10) || 0;
        const minutes = parseInt(parts[1], 10) || 0;
        const seconds = parseFloat(parts[2]) || 0;
        return Math.ceil(hours * 3600 + minutes * 60 + seconds);
    };

    const handleGetLength = (sectionArr) => {
        const podcastArr = []
        let sections = !sectionArr ? [...podcastSections] : sectionArr

        sections.forEach((e) => {
            if (e.type === "section") {
                podcastArr.push(e.value)
            }
        })

        podcastArr.unshift(podcastIntro.value)
        podcastArr.push(podcastOutro.value)

        if (isPreAd) {
            sections.unshift(preAdSelect)
        }

        if (isPostAd) {
            sections.push(postAdSelect)
        }

        let advertDuration = 0
        sections.forEach((e) => {
            if (e.type === "advert") {
                advertDuration = advertDuration + e.duration
            }
        })

        localStorage.setItem("tempAdContent", JSON.stringify({ podcastSectionArray: podcastArr, advertDuration: advertDuration}))
        props.getEstimatedPodcastLength()
    }

    const formatDuration = (seconds) => {
        const duration = moment.duration(seconds, 'seconds');
        const minutesPart = Math.floor(duration.asMinutes());
        const secondsPart = Math.floor(duration.asSeconds()) % 60;
        return `${String(minutesPart).padStart(2, '0')}:${String(secondsPart).padStart(2, '0')}`;
    }

    const handleAdSelect = (adObject, index) => {
        const copyArr = [...podcastSections]
        copyArr[index].value = adObject.value
        copyArr[index].duration = convertAdvertLength(adObject.duration)
        refreshIndexValues(copyArr)
        handleGetLength(copyArr)
    }

    useEffect(() => {
        checkMultipleSections()
    }, [podcastSections])

    const [isPodcastValid, setIsPodcastValid] = useState(null)
    const formatEpisodeSections = async () => {
        const copyArr = [...podcastSections]
        copyArr.unshift(podcastIntro)
        copyArr.push(podcastOutro)

        if (isPreAd)
            copyArr.unshift(preAdSelect)

        if (isPostAd)
            copyArr.push(postAdSelect)

        // A section should contain some content
        // An advert should have an Id !== 0
        let isValidPodcast = true
        copyArr.forEach((podcastEntry) => {
            if (podcastEntry.value === "" || podcastEntry.value === 0) {
                isValidPodcast = false
            }
        })

        if (!isValidPodcast) {
            return false
        }

        const createdPodcast = await props.handleCreatePodcast()

        const formattedEpisode = copyArr.map((e, index) => {
            return ({
                dateCreated: new Date().toISOString('O'),
                dateAmended: new Date().toISOString('O'),
                archived: true,
                id: e.originalId ? e.originalId : 0,
                podcastEpisodeId: 0,
                advertId: e.type === "section" ? 0 : e.value,
                name: e.type === "section" ? e.sectionName : "",
                input: "",
                topic: "",
                content: e.type === "section" ? e.value : "",
                voiceId: props.baseObject.defaultVoiceId,
                totalCharacters: 0,
                order: index + 1,
                inputTokens: 0,
                outputTokens: 0
            })
        })
        
        const businessPodcastEpisode = {
            dateCreated: new Date().toISOString('O'),
            dateAmended: new Date().toISOString('O'),
            archived: true,
            id: 0,
            podcastId: createdPodcast,
            name: props.baseObject.name,
            episodeNumber: 1,
            sections: formattedEpisode,
            totalCharacters: 0,
            inputTokens: 0,
            outputTokens: 0
        }

        // This is an array based on the episodeSection array
        return businessPodcastEpisode
    }

    const reformatEpisodeSections = () => {
        const copyArr = [...podcastSections]
        copyArr.unshift(podcastIntro)
        copyArr.push(podcastOutro)

        const copyPreviousSubmission = { ...props.previousGeneration }
        const copyPreviousSections = [...copyPreviousSubmission.sections]

        const formattedEpisode = copyArr.map((e, index) => {
            return ({
                dateCreated: new Date().toISOString('O'),
                dateAmended: new Date().toISOString('O'),
                archived: true,
                id: e.originalId ? e.originalId : 0,
                podcastEpisodeId: 0,
                advertId: e.type === "section" ? 0 : e.value,
                name: e.type === "section" ? e.sectionName : "",
                input: "",
                topic: "",
                content: e.type === "section" ? e.value : "",
                voiceId: props.baseObject.defaultVoiceId,
                totalCharacters: 0,
                order: index + 1,
                inputTokens: 0,
                outputTokens: 0
            })
        })

        // I don't know if this works
        for (let i = 0; i < copyPreviousSections.length; i++) {
            formattedEpisode[i].dateAmended = new Date().toISOString('O')
        }

        copyPreviousSubmission.sections = formattedEpisode

        return copyPreviousSubmission
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const changeArr = arrayMove(podcastSections, oldIndex, newIndex)
        refreshIndexValues(changeArr);
    };


    React.useImperativeHandle(ref, () => ({
        isValidated: async () => {

            if (props.previousGeneration === null) {
                setIsPodcastValid(null)
                //return true //Uncomment to not make an episode but progress to next step
                const episodeObject = await formatEpisodeSections();
                if (!episodeObject) {
                    setIsPodcastValid("Please fill in all sections and adverts")
                    return false
                }
                const makeEpisodeResponse = await props.handleBusinessEpisodeCreate(episodeObject);
                //props.handleStartGeneration(makeEpisodeResponse.id);

                if (makeEpisodeResponse.id !== 0) {
                    props.handleBusinessEpisodeStatus(makeEpisodeResponse.id);

                    const copySections = [...podcastSections]

                    const removeIntroOutro = makeEpisodeResponse.sections.filter((section => section.name !== "Intro" && section.name !== "Outro"))
                    for (let i = 0; i < copySections.length; i++) {
                        copySections[i].originalId = removeIntroOutro[i].id
                    }

                    const specificIntro = makeEpisodeResponse.sections.find((section) => section.name === "Intro")
                    const specificOutro = makeEpisodeResponse.sections.find((section) => section.name === "Outro")
                    setPodcastSections(copySections)
                    setPodcastIntro({ ...podcastIntro, originalId: specificIntro.id})
                    setPodcastOutro({ ...podcastOutro, originalId: specificOutro.id })

                    props.setTitleNumber(props.titleNumber + 1)
                    return true
                } else {
                    return false
                }
            } else {
                props.setB2BPreviewAudio(null)
                props.setB2BDownloadLink(null)
                props.setGenerationStep(1)

                const editedEpisodeObject = reformatEpisodeSections()
                if (!editedEpisodeObject) {
                    return false
                }

                const updateEpisodeResponse = await props.businesssEpisodeSectionUpdate(editedEpisodeObject)
                if (updateEpisodeResponse.id !== 0) {
                    props.handleBusinessEpisodeStatus(updateEpisodeResponse.id);

                    const copySections = [...podcastSections]

                    const removeIntroOutro = updateEpisodeResponse.sections.filter((section => section.name !== "Intro" && section.name !== "Outro"))
                    for (let i = 0; i < copySections.length; i++) {
                        copySections[i].originalId = removeIntroOutro[i].id
                    }

                    const specificIntroUpdate = updateEpisodeResponse.sections.find((section) => section.name === "Intro")
                    const specificOutroUpdate = updateEpisodeResponse.sections.find((section) => section.name === "Outro")
                    setPodcastSections(copySections)
                    setPodcastIntro({ ...podcastIntro, originalId: specificIntroUpdate.id })
                    setPodcastOutro({ ...podcastOutro, originalId: specificOutroUpdate.id })

                    return true
                }


            }

        },
    }));

    const populateContent = () => {
        
        setPodcastIntro({ type: "section", value: props.userGeneratedContent.intro, sectionName: "Intro" })
        setPodcastOutro({ type: "section", value: props.userGeneratedContent.outro, sectionName: "Outro" })

        const mapUserContent = props.userGeneratedContent.sections.map((e) => {
            return ({
                type: "section", value: e.content, id: 0, sectionName: e.title
            })
        })
        refreshIndexValues(mapUserContent)
        handleGetLength(mapUserContent)
    }
    useEffect(() => {
        if (props.userGeneratedContent) {
            populateContent()
        }
    }, [props.userGeneratedContent])

    const [isCharLimit, setIsCharLimit] = useState({})

    useEffect(() => {

        const isAnyInputOverfilled = Object.values(isCharLimit).some(tooMuch => tooMuch)
        props.setIsInputFull(isAnyInputOverfilled)

    }, [isCharLimit])

    return (
        <>
            <Col>
                <Row className="justify-content-between">
                    <Col sm="3">
                        <Select
                            className="react-select "
                            classNamePrefix="react-select"
                            name="singleSelect"
                            options={dropdownVoices}
                            value={selectedVoice}
                            onChange={(voice) => handleSelectNewVoice(voice)}
                        />
                    </Col>
                    {props.isBusiness ?
                    <p className="">Estimated Minutes: {formatDuration(props.estimatedLength)}</p> :
                    <p className="">Estimated podcast length: {formatDuration(props.estimatedLength)}</p>
                    }
                </Row>
                <Row>
                    {isPodcastValid !== null &&
                        <Col className="text-center">
                            <p style={{ color: "#F33B30" }}>{isPodcastValid}</p>
                        </Col>    
                    }
                </Row>
                <Row>
                    {props.previousGeneration !== null &&
                        <Col className="text-center mt-2">
                            <p style={{ color: "#F33B30" }}>You have already generated a podcast. Making changes and clicking next will regenerate (and use more credits) edited sections.</p>
                            <p style={{ color: "#F33B30" }}>If this was a mistake, your podcast will be <u style={{cursor: "pointer"}}>here</u></p>
                        </Col> 
                    }
                </Row>
                <Row>
                    <Col>
                        <FormGroup className="mt-4">
                            {props.isBusiness && (props.baseObject.includeAds &&
                                !isPreAd ? <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => setIsPreAd(true)}>Add Advert</button> :
                                <Row>
                                    <Col sm="4">
                                        <Label>Advert</Label>
                                        <Select
                                            className="react-select "
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            options={props.adverts}
                                            value={props.adverts.find((e) => e.value === preAdSelect.value)}
                                            onChange={(adObj) => handlePreAdSelect(adObj)}
                                        />
                                        <Button
                                            className="btn-icon btn-neutral dropdownIgnore"
                                            style={{ color: "#f33b30", position: "absolute", right: -15, bottom: -5 }}
                                            id="tooltip808966390"
                                            size="sm"
                                            type="button"
                                            onClick={() => handlePreAdRemove()}
                                        >
                                            <i className="fa fa-times" style={{ fontSize: 18 }} />
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        
                        </FormGroup>
                        <FormGroup>
                            <Label>Intro</Label>
                            <Input type="textarea" className="placeholderWhite inputStyle" value={podcastIntro.value} onChange={(e) => {
                                if (e.target.value.length <= 5000) {
                                    e.target.style.height = "auto"
                                    e.target.style.height = `${e.target.scrollHeight}px`
                                    e.target.style.maxHeight = "none"
                                    setPodcastIntro({ ...podcastIntro, value: e.target.value })
                                    e.target.style.removeProperty('border');
                                    setIsCharLimit(prevState => ({ ...prevState, "intro": false }));
                                } else {
                                    e.target.style.setProperty('border', '1px solid #F33B30', 'important');
                                    setIsCharLimit(prevState => ({ ...prevState, "intro": true }));
                                    setPodcastIntro({ ...podcastIntro, value: e.target.value })
                                }
                            }}
                                onBlur={() => handleGetLength()}
                                style={{ maxHeight: "none", height: 150 }}
                            />
                        </FormGroup>
                        <Row className="m-2">
                            <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => handleForceNewSection()}>Add New Section</button>
                            {props.isBusiness && props.baseObject.includeAds && <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => handleForceNewAd()}>Add Advert</button>}
                            {isCharLimit["intro"] && (
                                <p style={{ color: "#F33B30", marginLeft: "auto" }}>There is a maximum of 5000 characters per section</p>
                            )}
                        </Row>
                        <SortableList onSortEnd={onSortEnd} useDragHandle isMultiSections={isMultiSections}>
                            {podcastSections.map((e, index) => (
                                <SortableItem index={index}
                                    value={e}
                                    podcastSections={podcastSections}
                                    setPodcastSections={setPodcastSections}
                                    showAdverts={props.baseObject.includeAds}
                                    handleAddNewSection={handleAddNewSection}
                                    handleAddNewAd={handleAddNewAd}
                                    isMultiSections={isMultiSections}
                                    handleRemoveSection={handleRemoveSection}
                                    advertArray={props.adverts}
                                    handleAdSelect={handleAdSelect}
                                    handleRemoveAd={handleRemoveAd}
                                    isTitleEdit={isTitleEdit}
                                    setIsTitleEdit={setIsTitleEdit}
                                    handleGetLength={handleGetLength}
                                    setIsCharLimit={setIsCharLimit}
                                    isCharLimit={isCharLimit}
                                    isBusiness={props.isBusiness}
                                />
                            ))}
                        </SortableList>
                        <FormGroup>
                            <Label>Outro</Label>
                            <Input type="textarea" className="placeholderWhite inputStyle" value={podcastOutro.value} onChange={(e) => {

                                if (e.target.value.length <= 5000) {
                                    e.target.style.height = "auto"
                                    e.target.style.height = `${e.target.scrollHeight}px`
                                    e.target.style.maxHeight = "none"
                                    setPodcastOutro({ ...podcastOutro, value: e.target.value })
                                    e.target.style.removeProperty('border');
                                    setIsCharLimit(prevState => ({ ...prevState, "outro": false }));

                                } else {
                                    e.target.style.setProperty('border', '1px solid #F33B30', 'important');
                                    setIsCharLimit(prevState => ({ ...prevState, "outro": true }));
                                    setPodcastOutro({ ...podcastOutro, value: e.target.value })
                                }
                            }} onBlur={() => handleGetLength()}
                                style={{ maxHeight: "none", height: 150 }}
                            />
                        </FormGroup>
                        {isCharLimit["outro"] && (
                            <p style={{ color: "#F33B30", textAlign: "right" }}>There is a maximum of 5000 characters per section</p>
                        )}
                        <FormGroup className="mt-2">
                            {props.isBusiness && (props.baseObject.includeAds &&
                                !isPostAd ? <button className="buttonStyle buttonPrimary mr-2 ml-2" onClick={() => setIsPostAd(true)}>Add Advert</button> :
                                <Row>
                                    <Col sm="4">
                                        <Label>Advert</Label>
                                        <Select
                                            className="react-select "
                                            classNamePrefix="react-select"
                                            name="singleSelect"
                                            options={props.adverts}
                                            value={props.adverts.find((e) => e.value === postAdSelect.value)}
                                            onChange={(adObj) => handlePostAdSelect(adObj)}
                                        />
                                        <Button
                                            className="btn-icon btn-neutral dropdownIgnore"
                                            style={{ color: "#f33b30", position: "absolute", right: -15, bottom: -5 }}
                                            id="tooltip808966390"
                                            size="sm"
                                            type="button"
                                            onClick={() => handlePostAdRemove()}
                                        >
                                            <i className="fa fa-times" style={{ fontSize: 18 }} />
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {isPodcastValid !== null &&
                        <Col className="text-center">
                            <p style={{ color: "#F33B30" }}>{isPodcastValid}</p>
                        </Col>
                    }
                </Row>
                <Row>
                    <Col className="d-flex justify-content-end mt-4">
                        {props.isBusiness ?
                            <p className="">Estimated Minutes: {formatDuration(props.estimatedLength)}</p> :
                            <p className="">Estimated podcast length: {formatDuration(props.estimatedLength)}</p>
                        }
                    </Col>
                </Row>
            </Col>
        </>
    );
});

export default BStep4;
