import React, { useEffect, useState } from "react";
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import axios from "axios";
import ReactPlayer from "react-player";
import MyModal from "components/Shared/MyModal";
import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    UncontrolledTooltip
} from "reactstrap";

import AdWizard from "../../components/Wizards/AdWizard";
import WizardAdCreate from "../../components/Wizards/AdWizardSteps/WizardAdCreate";
import WizardAdLanguage from "../../components/Wizards/AdWizardSteps/WizardAdLanguage";
import WizardAdTarget from "../../components/Wizards/AdWizardSteps/WizardAdTarget";
import WizardAdPayment from "../../components/Wizards/AdWizardSteps/WizardAdPayment";
import Credits from "../../components/Wizards/AdWizardComps/Credits";
import AdVoice from "../../components/Wizards/AdWizardComps/AdVoice";
import AdMusic from "../../components/Wizards/AdWizardComps/AdMusic";
import AdError from "../../components/Wizards/AdWizardComps/AdError";


import HelpModal from "../../components/HelpModals/HelpModal";
import HelpCreateAd from "../../components/HelpModals/HelpModal/HelpCreateAd";
import HelpTranslate from "../../components/HelpModals/HelpModal/HelpTranslate";
import HelpTarget from "../../components/HelpModals/HelpModal/HelpTarget";
import HelpCheckout from "../../components/HelpModals/HelpModal/HelpCheckout";
import AdTutorialModal from "../../components/HelpModals/AdTutorialModal";

import TutorialCreateAd from "../../components/HelpModals/AdTutorialModal/TutorialCreateAd";
import TutorialHowItWorks from "../../components/HelpModals/AdTutorialModal/TutorialHowItWorks";
import TutorialTranslate from "../../components/HelpModals/AdTutorialModal/TutorialTranslate";
import TutorialTarget from "../../components/HelpModals/AdTutorialModal/TutorialTarget";
import TutorialCheckout from "../../components/HelpModals/AdTutorialModal/TutorialCheckout";


function AdsWithWizard() {


    const [modalOpen, setModalOpen] = useState(false)
    const [modalPage, setModalPage] = useState(null)
    const [modalComp, setModalComp] = useState(null)
    const [purchaseObject, setPurchaseObject] = useState(null)
    const runningFrom = new Date();
    const runningTo = new Date(runningFrom.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds
    const [baseObject, setBaseObject] = useState({
        id: 0,
        userId: getJWTToken().decoded.nameid,
        name: "",
        length: 6,
        isExactScript: false,
        categories: [],
        runningFrom: runningFrom,
        runningTo: runningTo,
        budget: 0,
        isAllCat: true,
        uploadedBase64: null,
        targeting: {
            geoLocations: [],
            age: [],
            interests: [],
            time: [],
            adPositions: [],
        }
    })


    // Translation Page Variables
    const [isTranslation, setIsTranslation] = useState(false)
    const [generatedTranslations, setGeneratedTranslations] = useState([]);

    const [isMissingCredits, setIsMissingCredits] = useState(false)
    const toggleModal = () => {
        if (modalOpen) {
            setModalPage(null)
            setModalComp(null)
            setPurchaseObject(null)
        }

        setModalOpen(!modalOpen)
    }


    const [selectedModalObject, setSelectedModalObject] = useState(null)
    const configureModal = () => {
        let title
        let comp
        let nextText

        switch (modalPage) {
            case ("credits"):
                title = "Add more Credits"
                comp = <Credits />
                nextText = "Next"
                break;
            case ("voice"):
                title = "Select Voice"
                comp = <AdVoice />
                nextText = "Save"
                break;
            case ("music"):
                title = "Select Music"
                comp = <AdMusic />
                nextText = "Save"
                break;
            case ("error"):
                title = "Something went wrong",
                    comp = <AdError />
                break;
        }

        setModalComp({ title, comp, nextText })
    }

    const handleSetModalPage = async (page, modalObject) => {
        if (modalObject) {
            await setSelectedModalObject(modalObject)
        }
        setModalPage(page)
    }


    useEffect(() => {
        if (modalPage)
            configureModal()
    }, [modalPage])

    useEffect(() => {
        if (modalComp)
            toggleModal()
    }, [modalComp])

    const [advertTargeting, setAdvertTargeting] = useState([])
    const getAdvertTargeting = async () => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_ADVERT_TARGETING,
                "get"
            );
            if (resp.status === 200) {
                setAdvertTargeting(resp.data.map((targ) => {
                    return ({
                        value: targ.id,
                        label: targ.name,
                        typeId: targ.typeId
                    })
                })

                )
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [categories, setCategories] = useState([])
    const getCategories = async () => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_CATEGORIES,
                "get"
            );
            if (resp.status === 200) {
                setCategories(resp.data.map((cat) => {
                    return ({
                        value: cat.id,
                        label: cat.name
                    })
                })

                )
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [adCredits, setAdCredits] = useState(0)
    const getCredits = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER_CREDIT.GET_CREDITS,
                "get"
            );
            if (resp.status === 200) {
                setAdCredits(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const completePurchase = () => {
        toggleModal()
        getCredits()
        getUserBalance()
        window.dispatchEvent(new Event("balanceUpdated"))
    }

    const renewCredits = async () => {
        if (!purchaseObject?.id) {
            if (purchaseObject.productId === 0) return

            try {
                const resp = await apiCall(
                    apiConfig.TRANSACTION.INIT_PURCHASE,
                    "post", purchaseObject
                );
                if (resp.status === 200) {

                    if (resp.data.isPaid) {
                        completePurchase()
                    } else {
                        setPurchaseObject(resp.data)
                    }


                }

            } catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                let url = `?id=${purchaseObject.id}`

                if (baseObject.id != 0) {
                    url = url + `&advertId=${baseObject.id}`
                }

                const resp = await apiCall(
                    apiConfig.TRANSACTION.CONFIRM_PURCHASE + url,
                    "put"
                );
                if (resp.status === 200) {
                    completePurchase()
                    setPurchaseComplete(true)
                }

            } catch (error) {
                console.error(error.message);
            }

        }
    }


    const [voices, setVoices] = useState([])
    const getVoices = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.VOICE.GET_VOICES}`
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const updatedVoices = res.data.map(voice => ({
                        ...voice,
                        voiceName: voice.voiceName.includes("-") ? voice.voiceName.split("-").pop() : voice.voiceName
                    }));

                    setVoices(updatedVoices);
                }
            })
            .catch((err) => {
                console.error(err)
            });

    }

    const [music, setMusic] = useState([])
    const getMusic = async () => {

        try {
            const resp = await apiCall(
                apiConfig.MUSIC.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setMusic(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    };

    const [products, setProducts] = useState([])
    const getProducts = async () => {
        // For now, just hardcore
        const tempProd = [
            {
                id: 1, typeId: 1, name: "10 credits", cost: 5
            },
            {
                id: 2, typeId: 1, name: "25 credits", cost: 10
            },
        ]
        setProducts(tempProd)


        //try {
        //    const resp = await apiCall(
        //        apiConfig.TRANSACTION.GET_ALL_TRANSACTION_PRODUCTS + `?typeId=1`,
        //        "get"
        //    );
        //    if (resp.status === 200) {
        //        setProducts(resp.data)
        //    }

        //} catch (error) {
        //    console.error(error.message);
        //}
    };

    const [userBalance, setUserBalance] = useState(0)
    const getUserBalance = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_USER_BALANCE,
                "get"
            );
            if (resp.status === 200) {
                setUserBalance(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const validateAdvertP1 = (ad) => {
        let data = ad

        if (!data) {
            data = localStorage.getItem("klaxonTempValidationStorage")
            data = JSON.parse(data)
        }

        if (data?.length === 0) {
            return false
        }

        if (data?.script === "")
            return false

        return true
    }

    const [singleLoadId, setSingleLoadId] = useState(null)
    const [isGeneratingError, setIsGeneratingError] = useState(false)
    const regenerateContent = async (id) => {
        setIsGeneratingError(false)
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];
        const newGenerationObject = contentSections.find((content) => content.id === id)

        const isValidVoice = voices.find((voice) => voice.voiceId == newGenerationObject.voiceId)
        const isValidMusic = music.find((song) => song.id == newGenerationObject.musicId)

        if (!isValidVoice || !isValidMusic) {
            console.error("Invalid content")
            return
        }

        setSingleLoadId(id)

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.REGENERATE_CONTENT + `?id=${id}`,
                "put", newGenerationObject
            );
            if (resp.status === 200) {
                const newContent = resp.data;

                const index = contentSections.findIndex((content) => content.id === newContent.id);

                if (index !== -1) {
                    contentSections[index] = newContent;
                }

                copyObject.content = contentSections;
                setBaseObject(copyObject)
                getCredits()

                console.log("resp.data", resp.data)

            }

        } catch (error) {
            if (error.data === "Insufficient credits") {
                setIsMissingCredits(true)
                setModalPage("credits")
            } else {
                setModalPage("error")
            }
            console.error(error.message);
        }

        setSingleLoadId(null)
    }

    const getVoicePreview = async (voiceIdString) => {
        try {
            const resp = await apiCall(
                apiConfig.VOICE.GET_PREVIEW + `?voiceId=${voiceIdString}&voiceStyleId=0&languageId=0&isEL=true`,
                "get"
            );
            if (resp.status === 200) {
                return resp.data
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [languages, setLanguages] = useState([])
    const getLanguages = async () => {
        try {
            const resp = await apiCall(
                apiConfig.VOICE.GET_LANGUAGES,
                "get"
            );
            if (resp.status === 200) {
                const formattedLang = resp.data.map((lang) => {
                    return ({
                        value: lang.id,
                        label: lang.name
                    })
                }).filter((lang) => lang.value != "en")
                setLanguages(formattedLang)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const [selectedVoiceUrl, setSelectedVoiceUrl] = useState(null)
    const [isVoicePreview, setIsVoicePreview] = useState(false)
    const [currentPlayingVoice, setCurrentPlayingVoice] = useState(null)
    const [loadingAudio, setLoadingAudio] = useState({
        isVoice: null,
        id: null,
        voiceModalPreview: false,
        voiceId: null
    })

    const handlePreviewVoice = async (voiceIdString, isVoice, id) => {
        await setSelectedVoiceUrl(null)
        await setIsVoicePreview(false)
        let previewUrl
        if (!voiceIdString)
            return

        setLoadingAudio({
            ...loadingAudio,
            isVoice: isVoice,
            id: id,
            voiceModalPreview: (!isVoice && !id) ? true : false,
            voiceIdString: voiceIdString
        })

        if (!voiceIdString.startsWith("http")) {
            const findVoice = voices.find((voice) => voice.voiceAiValue == voiceIdString)
            if (currentPlayingVoice == findVoice.voiceId) {
                setCurrentPlayingVoice(null)
                return
            }

            previewUrl = await getVoicePreview(voiceIdString)
            setCurrentPlayingVoice(findVoice.voiceId)
        } else {
            const findMusic = music.find((voice) => voice.url == voiceIdString)

            if (currentPlayingVoice == findMusic.id) {
                setCurrentPlayingVoice(null)
                return
            }
            setCurrentPlayingVoice(findMusic.id)
            previewUrl = voiceIdString
        }



        await setSelectedVoiceUrl(previewUrl)
        await setIsVoicePreview(true)
        setLoadingAudio({
            ...loadingAudio,
            isVoice: null,
            id: null,
            voiceModalPreview: null,
            voiceIdString: null
        })
    }

    const wizardSteps = [
        {
            title: "Create",
            component: < WizardAdCreate />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            selectedModalObject: selectedModalObject,
            setSelectedModalObject: setSelectedModalObject,
            regenerateContent: regenerateContent,
            handlePreviewVoice: handlePreviewVoice,
            voices: voices,
            music: music,
            singleLoadId: singleLoadId,
        },
        {
            title: "Translate",
            component: <WizardAdLanguage />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            languages: languages,
            voices: voices,
            music: music,
            baseObject: baseObject,
            setBaseObject: setBaseObject,
            isTranslation: isTranslation,
            setIsTranslation: setIsTranslation
        },
        {
            title: "Target",
            component: <WizardAdTarget />,
            setModalPage: handleSetModalPage,
            advertTargeting: advertTargeting,
            categories: categories,
            baseObject: baseObject,
            setBaseObject: setBaseObject
        },
        {
            title: "Checkout",
            component: <WizardAdPayment />,
            setModalPage: handleSetModalPage,
            baseObject: baseObject,
            setBaseObject: setBaseObject
        },
    ]


    const makeCalls = async () => {
        getAdvertTargeting()
        getCategories()
        getCredits()
        getVoices()
        getLanguages()
        getMusic()
        getProducts()
        getUserBalance()
    }

    useEffect(() => {
        makeCalls()
    }, [])



    const addVoice = () => {
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];

        const index = contentSections.findIndex((content) => content.id === selectedModalObject.id);

        if (index !== -1) {
            contentSections[index].voiceId = selectedModalObject.voiceId;
            contentSections[index].voiceAiValue = selectedModalObject.voiceAiValue
            contentSections[index].updateReq = true
        }

        copyObject.content = contentSections;
        setBaseObject(copyObject)
        toggleModal()
    }

    const addMusic = () => {
        const copyObject = { ...baseObject };
        const contentSections = [...copyObject.content];

        const index = contentSections.findIndex((content) => content.id === selectedModalObject.id);

        if (index !== -1) {
            contentSections[index].musicId = selectedModalObject.musicId;
            contentSections[index].updateReq = true
        }

        copyObject.content = contentSections;
        setBaseObject(copyObject)
        toggleModal()
    }

    const getSaveFunc = () => {
        const pageTitle = modalComp?.title

        switch (pageTitle) {
            case ("Add more Credits"):
                return renewCredits
            case ("Select Voice"):
                return addVoice
            case ("Select Music"):
                return addMusic
            default:
                break;
        }
    }

    useEffect(() => {
        if (!modalOpen) {
            setSelectedVoiceUrl(null)
            setIsVoicePreview(false)
            setCurrentPlayingVoice(null)
        }
    }, [modalOpen])

    const titles = [
        "Create an Advert",
        "Choose Languages",
        "Use your Advert",
        "Make your payment"
    ]

    const descriptions = [
        "Create your own audio advert here and use it in podcasts created by Klaxon users or download it to use elsewhere.",
        "Broadcast or download your advert in English or download it in multiple languages",
        "Choose how you'll use your advert and set your advertising parameters",
        "Review your advertising campaign budget and any download fees and make a secure payment"
    ]
    const [selectedTitle, setSelectedTitle] = useState(0)
    const [titleToShow, setTitleToShow] = useState("")
    const [descriptionToShow, setDescriptionToShow] = useState("")

    useEffect(() => {
        setTitleToShow(titles[selectedTitle])
        setDescriptionToShow(descriptions[selectedTitle])
    }, [selectedTitle])

    // Title Stuf
    const [finishingTitle, setFinishingTitle] = useState(false)
    const makeEndTitles = () => {
        let title
        if (finishingTitle.downloadAmount !== 0 && finishingTitle.budget === 0) { //Only download
            title = "Download your ad"
        } else if (finishingTitle.downloadAmount === 0 && finishingTitle.budget !== 0) { //Only network
            title = "Go to ads section"
        } else {
            title = "Download your ad or go to ads section"
        }

        setTitleToShow(title)
        setDescriptionToShow("")
    }

    useEffect(() => {
        if (finishingTitle) {
            makeEndTitles()
        }
    }, [finishingTitle])

    const [isHelpModalOpen, setIsHelpModalOpen] = useState(false)
    const helpModalList = {
        0: <HelpCreateAd />,
        1: <HelpTranslate />,
        2: <HelpTarget />,
        3: <HelpCheckout />,
    }
    const [selectedHelpModal, setSelectedHelpModal] = useState(null)
    const handleToggleHelpModal = () => {
        if (isHelpModalOpen) {
            setSelectedHelpModal(null)
        } else {
            setSelectedHelpModal(helpModalList[selectedTitle])
        }
    }

    useEffect(() => {
        if (selectedHelpModal !== null) {
            setIsHelpModalOpen(true)
        } else {
            setIsHelpModalOpen(false)
        }
    }, [selectedHelpModal])

    const [isShowTutorial, setIsShowTutorial] = useState(true)
    const [tutorialPage, setTutorialPage] = useState(0)
    const tutorialPages = {
        0: <TutorialCreateAd />,
        1: <TutorialHowItWorks />,
        2: <TutorialTranslate />,
        3: <TutorialTarget />,
        4: <TutorialCheckout />
    }
    const [selectedTutorialPage, setSelectedTutorialPage] = useState(tutorialPages[tutorialPage])

    // Sets page to top on tutorial close
    useEffect(() => {
        setSelectedTutorialPage(tutorialPages[tutorialPage])
    }, [tutorialPage])
    
    useEffect(() => {
        const panel = document.querySelector('.main-panel');
        if (panel) {
            panel.scrollTop = 0;
        }
    }, [isShowTutorial])

    return (
        <>
            <HelpModal isHelpModalOpen={isHelpModalOpen} setSelectedHelpModal={setSelectedHelpModal} selectedHelpModal={selectedHelpModal} />
            <ReactPlayer url={selectedVoiceUrl}
                playing={isVoicePreview}
                controls={false}
                height='0px'
                width='0px'
                className="podcastPlayer"
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                onEnded={() => {
                    setSelectedVoiceUrl(null)
                    setIsVoicePreview(false)
                    setCurrentPlayingVoice(null)

                }}
            />
            <MyModal isOpen={modalOpen} toggle={toggleModal} title={modalComp?.title} buttonAction={getSaveFunc()} height={modalComp?.title !== "Something went wrong" ? "50vh" : "10vh"} nextText={modalComp?.nextText ? modalComp?.nextText : false} width={modalComp?.title != "Add more Credits" && "50%"} styleProp={{
                overflow: "auto"
            }}>
                {modalComp?.comp && React.cloneElement(modalComp.comp, { selectedModalObject, setSelectedModalObject, voices, music, handlePreviewVoice, products, setPurchaseObject, purchaseObject, userBalance, renewCredits, currentPlayingVoice, loadingAudio })}
            </MyModal>
            {isShowTutorial &&
                <div className="content">
                    {selectedTutorialPage &&
                        <AdTutorialModal tutorialPage={tutorialPage} setTutorialPage={setTutorialPage} selectedTutorialPage={selectedTutorialPage} setIsShowTutorial={setIsShowTutorial} />
                    }
                </div>
            }

            <div className="content" style={{ display: isShowTutorial && "none" }}>
                <Card className="formStyle text-white">
                    <CardHeader>
                        <Col>
                            <Row className="justify-content-between">
                                <div>
                                    <CardTitle tag="h4">{titleToShow}</CardTitle>
                                    <p style={{ margin: 0, fontSize: 14 }}>
                                        {descriptionToShow}
                                    </p>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: 5, }} className="fadedKlaxonRed">
                                    <i className="fa fa-info-circle" id="creditInfo" />
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="creditInfo"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}

                                    >
                                        <p style={{ margin: 0 }}>Users get 25 credits free each month. You can purchase additional credits</p>
                                    </UncontrolledTooltip>
                                    <p style={{ margin: 0 }}>Ad Generation Credits</p>
                                    <p style={{ margin: 0, border: "", padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 25 }}>{adCredits} credits remaining</p>
                                    <p style={{ margin: 0, backgroundColor: " rgba(243,59,48,255)", padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 25, cursor: "pointer" }} onClick={() => setModalPage("credits")}>Add more credits</p>
                                </div>

                            </Row>
                        </Col>
                    </CardHeader>
                    <CardBody>
                        <AdWizard wizardSteps={wizardSteps}
                            baseObject={baseObject}
                            setBaseObject={setBaseObject}
                            setIsMissingCredits={setIsMissingCredits}
                            validateAdvertP1={validateAdvertP1}
                            getCredits={getCredits}
                            setModalPage={setModalPage}
                            voices={voices}
                            loadingAudio={loadingAudio}
                            isGeneratingError={isGeneratingError}
                            setIsGeneratingError={setIsGeneratingError}
                            setSelectedTitle={setSelectedTitle}
                            generatedTranslations={generatedTranslations}
                            setFinishingTitle={setFinishingTitle}
                        />
                    </CardBody>
                </Card>
                <div style={{ display: "flex", justifyContent: "space-between" }} className="labelText">
                    <div onClick={() => handleToggleHelpModal()}
                        style={{ display: "inline-flex", gap: 10, alignItems: "center", cursor: "pointer", backgroundColor: "", padding: 10 }}
                    >
                        <i className="fa fa-info-circle" />
                        <p style={{ margin: 0 }}>Help</p>

                    </div>
                    <div onClick={() => setIsShowTutorial(true)}
                        style={{ display: "inline-flex", gap: 10, alignItems: "center", cursor: "pointer", backgroundColor: "", padding: 10 }}
                    >
                        <p style={{ margin: 0 }}>Tutorial</p>
                        <i className="fa fa-info-circle" />

                    </div>
                </div>

            </div>

        </>
    );
}

export default AdsWithWizard;