/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import {
    Route,
    Navigate,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    useLocation
} from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Light from "layouts/Light.js"

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.3.1";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "assets/css/klaxonai-css.css"
import "assets/css/lightmode.css"
import "assets/scss/index.scss"

import CookieConsent, { Cookies } from "react-cookie-consent"

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);

    const login = (accessToken, refreshToken) => {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
    };

    const logout = () => {
        setAccessToken(null);
        setRefreshToken(null);
    };

    return (
        <AuthContext.Provider value={{ accessToken, refreshToken, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

const AdminGuard = ({ children }) => {
    const location = useLocation();

    const allowedRoutes = [
        "/admin/dashboard",
        "/admin/create-ad",
        "/admin/ads",
        "/admin/not-available"
    ];

    const currentPath = location.pathname;

    // Uncomment to lock pages with above array
    //if (!allowedRoutes.includes(currentPath)) {
    //    // Redirect to not-available if path is not allowed
    //    return <Navigate to="/admin/not-available" replace />;
    //}

    return children;
};

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter(
    createRoutesFromElements(
        //   <Route path="/">
        //     <Route path="/auth/*" element={<AuthLayout />} />
        //     <Route path="/admin/*" element={<AdminLayout />} />
        //     <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
        //   </Route>

        <Route>
            <Route path="/light/*" element={<Light />} />
            <Route path="/auth/*" element={<AuthLayout />} />
            <Route
                path="/admin/*"
                element={
                    <AdminGuard>
                        <AdminLayout />
                    </AdminGuard>
                }
            />
            <Route path="/*" element={<Light />} />
            <Route path="/" element={<Navigate to="/home" replace />} />
        </Route>
    )
);

root.render(
    <React.Fragment>
        <RouterProvider router={router} />
        <CookieConsent
            style={{ backgroundColor: "#222", color: "white", fontSize: 16, position: "fixed", zIndex: 10 }}
            buttonText="Accept all cookies"
            buttonStyle={{ backgroundColor: "#F33B30", borderRadius: 5, fontSize: 14, fontWeight: "bold", paddingBottom: 2, minWidth: 140, minHeight: 40, color: "#fff" }}
            enableDeclineButton
            declineButtonText="Decline cookies"
            declineButtonStyle={{ backgroundColor: "#404040", borderRadius: 5, fontSize: 14, fontWeight: "bold", paddingBottom: 2, minWidth: 140, minHeight: 40, color: "#fff" }}

        ><p>This website uses cookies to enhance the user experience.</p></CookieConsent>
    </React.Fragment>
);
